
export default {
  name: "PageContentSections",
  props: {
    zone: Object,
  },
  methods: {
    isClientOnly(componentName) {
      // List of components that should only render on the client side
      const clientOnlyComponents = ['ComponentPostImageSection']; // Add more names as required
      return clientOnlyComponents.includes(componentName);
    }
  }
};
