import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6a06c6b6&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuSpacer: require('/codebuild/output/src461350180/src/onvocado-landing/components/global/MenuSpacer.vue').default,SectionHero: require('/codebuild/output/src461350180/src/onvocado-landing/components/section/Hero.vue').default,SectionEditorPreview: require('/codebuild/output/src461350180/src/onvocado-landing/components/section/EditorPreview.vue').default,PageContentSections: require('/codebuild/output/src461350180/src/onvocado-landing/components/PageContentSections.vue').default,Footer: require('/codebuild/output/src461350180/src/onvocado-landing/components/global/Footer.vue').default,SEO: require('/codebuild/output/src461350180/src/onvocado-landing/components/global/SEO.vue').default,PreviewModeWrapper: require('/codebuild/output/src461350180/src/onvocado-landing/components/global/PreviewModeWrapper.vue').default})
