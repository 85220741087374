
export default {
  name: "EditorPreview",
  data() {
    return {
      slides: [
        {
          name: "Select",
          imgUrl: "/images/templates.webp",
          alt: "Template selection preview"
        },
        {
          name: "Customize",
          imgUrl: "/images/editor.webp",
          alt: "Editor preview"
        },
        {
          name: "Publish",
          imgUrl: "/images/dashboard.webp",
          alt: "Dashboard preview"
        },
        {
          name: "Analyze",
          imgUrl: "/images/analytics.webp",
          alt: "Analytics preview"
        }
      ],
      activeIndex: 0,
      swiper: null,
      windowWidth: 0,
    };
  },
  computed: {
    swiperOptions() {
      return {
        ...(this.isMobile ? {} : {
          effect: 'fade',
          speed: 1000,
          fadeEffect: {
            crossFade: true
          }
        }),
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        // centeredSlides: true,
        spaceBetween: 10,
        slidesPerView: 1.15,
        on: {
          slideChange: () => {
            this.activeIndex = this.swiper.realIndex;
          },
        },
        breakpoints: {
          // when window width is >= 768px
          768: {
            centeredSlides: false,
            spaceBetween: 0,
            slidesPerView: 1,
            // slidesPerGroup: 1,
          },
        }
      };
    },
    isMobile() {
      return this.$device.isMobile && this.windowWidth < 768;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeSwiper();
    });
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
      if (this.swiper) {
        this.swiper.destroy(true, true);
        this.initializeSwiper();
      }
    },
    initializeSwiper() {
      this.swiper = new this.$swiper(this.$refs.swiperElement, {
        modules: [this.$swiperModules.EffectFade, this.$swiperModules.Autoplay],
        ...this.swiperOptions,
      });
    },
    setActiveSlide(index) {
      this.swiper.slideTo(index);
      this.activeIndex = index;
    },
  }
}
