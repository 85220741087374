
import ImageMeta from "@/mixins/imageMeta.js";

export default {
  name: "Hero",
  mixins: [ImageMeta],
  props: {
    heroData: Object,
  },
  computed: {
    imageAttributes() {
      return this.getAttribute(this.heroData, 'image.data.attributes');
    },
    supportingImageAttributes() {
      return this.getAttribute(this.heroData, 'supportingImage.data.attributes');
    },
    supportingImageAlternativeText() {
      return (this.supportingImageAttributes && this.supportingImageAttributes.alternativeText) ? this.supportingImageAttributes.alternativeText : '';
    },
  }
}
