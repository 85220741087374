
export default {
  name: "Clients",
  mounted() {
    let ul = this.$refs.logos;
    ul.insertAdjacentHTML('afterend', ul.outerHTML);
    ul.nextSibling.setAttribute('aria-hidden', 'true');
    ul.classList.add('animate-infinite-scroll');
    ul.nextSibling.classList.add('animate-infinite-scroll');
  }
}
